/*
| Developed by Starton
| Filename : AuthLayout.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

'use client'

import React from 'react'
import { Box, Stack, Typography, Button } from '@mui/material'
import { useSessionMe } from '../api/session.hooks'
import { styled } from '@mui/material/styles'
import { useAuthContext } from '../provider'

/*
|--------------------------------------------------------------------------
| Styled components
|--------------------------------------------------------------------------
*/
const BlurredContent = styled(Box)(({ theme }) => ({
	filter: 'blur(8px)',
	opacity: 0.6,
	pointerEvents: 'none',
	userSelect: 'none',
}))

const AuthContainer = styled(Stack)(({ theme }) => ({
	textAlign: 'center',
	padding: theme.spacing(4),
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '100%',
	maxWidth: '500px',
	zIndex: 10,
}))

/*
|--------------------------------------------------------------------------
| Types
|--------------------------------------------------------------------------
*/
type Props = {
	children: React.ReactNode
	exampleContent: React.ReactNode
	title?: string
	description?: string
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const AuthLayout = ({
	children,
	exampleContent,
	title = 'Authentication Required',
	description = 'Please log in or create an account to access this feature',
}: Props) => {
	const { data: session, isLoading } = useSessionMe()
	const { openAuthDialog } = useAuthContext()

	if (isLoading) {
		return (
			<Box position="relative">
				<BlurredContent>{exampleContent}</BlurredContent>
			</Box>
		)
	}

	if (!session) {
		return (
			<Box position="relative">
				<BlurredContent>{exampleContent}</BlurredContent>

				<AuthContainer spacing={3}>
					<Typography variant="h4" component="h1">
						{title}
					</Typography>
					<Typography variant="body1" color="text.secondary">
						{description}
					</Typography>
					<Stack direction="row" spacing={2} justifyContent="center">
						<Button onClick={() => openAuthDialog('login')} variant="contained" color="primary">
							Log In
						</Button>
						<Button onClick={() => openAuthDialog('register')} variant="outlined" color="primary">
							Create Account
						</Button>
					</Stack>
				</AuthContainer>
			</Box>
		)
	}

	return <>{children}</>
}

/*
| Developed by Starton
| Filename : AuthLoginForm.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

'use client'

import { useForm, Validator } from '@tanstack/react-form'
import { yupValidator } from '@tanstack/yup-form-adapter'
import { Button, Stack, Typography, Divider, Alert } from '@mui/material'
import { Form, MetamaskIcon, PasswordInput } from '@starton/design-system'
import { TextInput, CheckboxInput } from '@starton/design-system'
import * as yup from 'yup'
import { useApiError } from '@/hooks/useApiError'
import { SessionLoginDto } from '../api/session.dto'
import { useSWRConfig } from 'swr'
import { SessionEndpoint, sessionEndpointUrls } from '@/modules/auth/api/session.endpoint'
import { userEndpointUrls } from '@/modules/user/api/user.endpoint'
import { toast } from 'sonner'
import GoogleIcon from '@mui/icons-material/Google'
import GitHubIcon from '@mui/icons-material/GitHub'
import { IN_DEV } from '@/config/common.config'
import { useAuthContext } from '../provider'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const AuthLoginForm = () => {
	const { cache, mutate } = useSWRConfig()
	const [error, handleError, resetError] = useApiError()
	const { closeAuthDialog } = useAuthContext()

	// Form
	//--------------------------------------------------------------------------
	const form = useForm<SessionLoginDto, Validator<SessionLoginDto>>({
		defaultValues: {
			email: IN_DEV ? 'user@starton.com' : '',
			password: IN_DEV ? 'DGsrgqr5@B@JETQ&DRgWoSW3' : '',
			rememberMe: false,
		},
		validators: { onChangeAsync: resetError },
		validatorAdapter: yupValidator(),
		onSubmit: async ({ value }) => {
			try {
				const user = await SessionEndpoint.store(value).fetcher()
				await mutate(SessionEndpoint.me().uri, user)
				closeAuthDialog()
				toast.success('You are connected')
			} catch (error) {
				handleError(error)
			}
		},
	})

	// Render
	//--------------------------------------------------------------------------
	return (
		<Form form={form} loadingMessage="Logging in...">
			<Stack spacing={2}>
				<Alert severity="info" icon={false}>
					Coming soon: OIDC connection (Google, GitHub) and Metamask will be available soon.
				</Alert>

				<Stack spacing={2} direction="row">
					<Button disabled variant="outlined" fullWidth startIcon={<GoogleIcon />} size="large">
						Google
					</Button>
					<Button disabled variant="outlined" fullWidth startIcon={<GitHubIcon />} size="large">
						GitHub
					</Button>
					<Button disabled variant="outlined" fullWidth startIcon={<MetamaskIcon />} size="large">
						Metamask
					</Button>
				</Stack>

				<Divider>or</Divider>

				<TextInput
					form={form}
					name="email"
					label="Email"
					helperText="Enter your email address"
					validators={{
						onChange: yup.string().email('Must be a valid email').required('Email is required'),
					}}
				/>

				<PasswordInput
					form={form}
					name="password"
					label="Password"
					helperText="Enter your password"
					validators={{
						onChange: yup.string().required('Password is required'),
					}}
				/>

				<CheckboxInput form={form} name="rememberMe" label="Remember me" />

				{error ? (
					<Typography variant="subtitle2" color="error">
						{error}
					</Typography>
				) : null}

				<Button variant="contained" color="primary" type="submit" size="large" fullWidth>
					Login
				</Button>
			</Stack>
		</Form>
	)
}

/*
| Developed by Starton
| Filename : common.config.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

export const HOST = process.env.NEXT_PUBLIC_HOST as string
export const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN as string
export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION as string
export const COOKIE_SESSION_NAME = process.env.NEXT_PUBLIC_COOKIE_SESSION_NAME as string
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST as string
export const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV as string
export const IN_DEV = APP_ENV === 'development'
